import useFetchService from '@/composables/useFetchService';

export default function useReviewService() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();
  const fetchService = useFetchService({ base: runtimeConfig.public.apiUrl });

  /********************
   * FUNCTIONS         *
   ********************/
  function create(lang: string, data: any) {
    return fetchService.post(`/reviews/reviews/`, lang, data);
  }

  function getReviews(lang: string, slug: string, params: any) {
    return fetchService.get(`/search/reviews/${slug}/`, lang, { params });
  }

  function loginAndCreate(lang: string, data: any) {
    return fetchService.post(`/reviews/login-and-review/`, lang, data);
  }

  function registerAndCreate(lang: string, data: any) {
    return fetchService.post(`/reviews/register-and-review/`, lang, data);
  }

  function vote(lang: string, data: any) {
    return fetchService.post(`/reviews/useful/`, lang, data);
  }

  return {
    create,
    getReviews,
    loginAndCreate,
    registerAndCreate,
    vote,
  };
}
